<template>

  <div class="video_grid_wrapp">

    <IVideo muted="muted" v-for="(item, index) in filtered" :key="index" :videoSrc="item.videoSrc" v-model="item.videoSrc" :videoHd="item.hd" :videoLive="item.live" :videoUsersWatching="item.watching" :videoModelName="item.name" :videoModelAge="item.age" :videoModelCountry="item.country" :videoPosterSrc="item.posterSrc" @click="showModalSignUp()" />

  </div>

  <pagination v-model="pagination.page" :records="pagination.total" :per-page="pagination.per_page" @paginate="updatePaginationVideo"/>

</template>

<script>
import src from '../controllers/components/VideoGridCtrl.js';
export default src;
</script>