
export default {
	name: "IHeader",
	data() {
		return {
			modalAgreement: false,
			active: false,
		};
	},
  methods: {
    showModalSignUp() {
			this.$parent.$refs.modalSignUp.onShow(true);
			// console.log(this.$parent.$refs);
    },
  },
};