<template>
	
	<footer class="footer">

		<div class="footer_top">
		
			<div class="footer_container">
		
				<ul class="footer_list_certificates">
					<li><img src="../assets/images/certificates/norton.png" alt="logo"></li>
					<li><img src="../assets/images/certificates/pci.png" alt="logo"></li>
					<li><img src="../assets/images/certificates/visa.png" alt="logo"></li>
					<li><img src="../assets/images/certificates/mastercard.png" alt="logo"></li>
				</ul>

				<div class="footer_top_desc">
					<p>All transactions are handled securely and discretely by our authorized merchants (Multicards and SegPay).</p>
					<p>For Billing Inquiries you can visit <a href="#0">SegPay</a> or <a href="#0">Paymentstrust</a> as our authorized sales agents.</p>
				</div>
		
			</div>
		
		</div>
		
		<div class="footer_bottom">
			
			<div class="footer_container">
				<p class="text_center">
					Mirrana corp.s.r.o., Rybná 716/24, Praha, Czech Republic Phone number: +420775931202
				</p>
				<p class="text_center">GirlsLive.com 2008-{{ new Date().getFullYear() }} © All rights reserved</p>
			</div>

		</div>

	</footer>	

</template>

<script>
export default {
  name: 'Footer',
}
</script>