<template>
		
	<header class="header">
			
		<div class="header_top">
			
			<div class="header_logo" @click="showModalSignUp()">
				<a href="#0">
					<img src="@/assets/images/logo.png" alt="img">
				</a>
			</div>

			<div class="header_top_right">

				<ul class="header_list_chat" @click="showModalSignUp()">
					<li>
						<a href="#0">
							<img src="../assets/images/woman.png" alt="img" class="header_list_chat_img">
							<div>
								<span class="header_list_start_chat">Single girls 6114</span>
								<span class="header_list_start_online">online now: 335</span>
							</div>
						</a>
					</li>
					<li>
						<a href="#0">
							<img src="../assets/images/webcam.png" alt="img" class="header_list_chat_img">
							<div>
								<span class="header_list_start_chat">Online Chat</span>
								<span class="header_list_start_online">ON WEBCAM: 40</span>
							</div>
						</a>
					</li>
				</ul>

				<div class="header_login">
					<a href="#0" class="btn btn_orange_gradient" @click="showModalSignUp()">Create account</a>
				</div>

			</div>

		</div>

		<div class="header_bottom">

			<nav class="header_nav" @click="showModalSignUp()">

				<ul class="header_menu">

					<li>
						<a href="#!">All Girls</a>
					</li>

					<li>
						<a href="#!">Hot Flirt</a>
					</li>

					<li>
						<a href="#!">Soul mate</a>
					</li>

					<li>
						<a href="#!">Mature</a>
					</li>

					<li>
						<a href="#!">Newbie</a>
					</li>

					<li>
						<a href="#!">Amateur</a>
					</li>

					<li>
						<a href="#!">Exclusive</a>
					</li>
					
				</ul>

			</nav>

			<ul class="header_sort" @click="showModalSignUp()">
				<li>
					<a href="#0">
						<img src="../assets/images/icons/filter-list.png" alt="img">
					</a>
				</li>
				<li>
					<a href="#0">
						<img src="../assets/images/icons/filter-tiles.png" alt="img">
					</a>
				</li>
				<li>
					<a href="#0">
						<img src="../assets/images/icons/filter-box.png" alt="img">
					</a>
				</li>
			</ul>
				
		</div>
	
	</header>

</template>

<script>
import src from '@/controllers/components/HeaderCtrl.js';
export default src;
</script>
