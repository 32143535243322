import { createApp } from 'vue';
import App from '@/App.vue';

import router from '@/router';

// packages
import '@/assets/styles/pagination.scss';

import '@/assets/styles/video.scss';
import '@/assets/styles/video-grid.scss';


// import '@/assets/styles/popup.scss';
import '@/assets/styles/modal.scss';
import '@/assets/styles/modal-registration.scss';
import '@/assets/styles/modal-agreement.scss';

import '@/assets/styles/page-error.scss';

import '@/assets/styles/main.scss';
import '@/assets/styles/breakpoints.scss';


// packages
import Pagination from 'v-pagination-3';


// Custom packages
import IHeader from '@/components/Header.vue';
import IFooter from '@/components/Footer.vue';

import IVideo from '@/components/Video.vue';
import IVideoGrid from '@/components/VideoGrid.vue';

import IModal from '@/components/Modal.vue';


const app = createApp(App);

// packages
app.component('pagination', Pagination);


// Custom packages
app.component('IHeader', IHeader);

app.component('IVideo', IVideo);

app.component('IVideoGrid', IVideoGrid);

app.component('IFooter', IFooter);

app.component('IModal', IModal);

app.use(router);

app.mount('#app');


