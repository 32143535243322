<template>
  <router-view />
  <Preloader class="preloader-general" :showHide="isGeneralPreloader" />
</template>

<script>

import Preloader from "@/components/Preloader.vue";

export default {
  name: "App",
  components: {
    Preloader
  },
  watch: {
    '$route':{
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Enjoy Live Webcam with Ukrainian and Russian Girls'
      },
    }
  },
  provide() {
    return {
      provideShowPreloader: this.showPreloader,
      provideHidePreloader: this.hidePreloader
    }
  },
  data() {
    return {
      isGeneralPreloader: false
    }
  },
  methods: {
    showPreloader() {
      this.isGeneralPreloader = true;
    },
    hidePreloader() {
      this.isGeneralPreloader = false;
    }
  }
}
</script>

<style lang="scss">

  .preloader-general {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    height: 100%;
    width: 100%;
    background-image: -webkit-linear-gradient(left, #0674fd, #00d0f9);
  }
</style>