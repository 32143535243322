
export default {
  name: 'IVideoGrid',
	data() {
		return {
			pagination: {
				page: 1,
				total: 50,
				per_page: (window.screen.width >= 1600) ? 25 :
									(window.screen.width >= 1170) ? 16 :
									(window.screen.width >= 768) ? 12 : 
									(window.screen.width >= 470) ? 4 : 3,
			},
			filtered: [],
			models: [
				{
					name: 'Alina ',
					watching: 372,
					age: 29,
					hd: true,
					live: true,
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5108.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-05-20-931.mp4`),
				},
				{
					name: 'Olga',
					watching: 564,
					age: 34,
					hd: true,
					live: true,
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5109.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-08-32-816.mp4`),
				},
				{
					name: 'Vicky',
					watching: 119,
					age: 27,
					hd: true,
					live: true,
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5107.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-10-04-856.mp4`),
				},
				{
					name: 'Alyona',
					watching: 345,
					age: 31,
					hd: true,
					live: true,
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5110.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-11-32-874.mp4`),
				},
				{
					name: 'Christina',
					watching: 570,
					age: 24,
					hd: true,
					live: true,
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5111.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-13-32-343.mp4`),
				},
				{
					name: 'Olga',
					watching: 730,
					age: 26,
					hd: true,
					live: true,
					country: `PL2`,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-15-14-636.mp4`),
					posterSrc: require(`@/assets/images/video-posters/Frame 5112.jpg`),
				},
				{
					name: 'July',
					watching: 370,
					age: 22,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-17-03-296.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5113.jpg`),
				},
				{
					name: 'Anastasia',
					watching: 165,
					age: 20,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-22-52-285.mp4`),
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5116.jpg`),
				},
				{
					name: 'Ksenia',
					watching: 231,
					age: 20,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-24-43-076.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5117.jpg`),
				},
				{
					name: 'Daisy',
					watching: 437,
					age: 24,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-26-08-052.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5118.jpg`),
				},
				{
					name: 'Ruslana',
					watching: 98,
					age: 23,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-27-23-829.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5119.jpg`),
				},
				{
					name: 'Irina',
					watching: 234,
					age: 26,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-30-15-734.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5120.jpg`),
				},
				{
					name: 'Katrina',
					watching: 276,
					age: 28,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-31-32-113.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5121.jpg`),
				},
				{
					name: 'Nastusha ',
					watching: 789,
					age: 22,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-32-45-765.mp4`),
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5122.jpg`),
				},
				{
					name: 'Alice',
					watching: 321,
					age: 29,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-34-35-034.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5123.jpg`),
				},
				{
					name: 'Gina ',
					watching: 115,
					age: 26,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-36-55-007.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5124.jpg`),
				},
				{
					name: 'Karisha',
					watching: 380,
					age: 25,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-38-36-300.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5125.jpg`),
				},
				{
					name: 'July',
					watching: 425,
					age: 27,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-40-26-963.mp4`),
					country: `PL2`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5126.jpg`),
				},
				{
					name: 'Ksenia',
					watching: 312,
					age: 27,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-44-28-788.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5127.jpg`),
				},
				{
					name: 'Sasha ',
					watching: 578,
					age: 23,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-46-03-537.mp4`),
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5128.jpg`),
				},
				{
					name: 'Irina',
					watching: 214,
					age: 29,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-48-47-440.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5129.jpg`),
				},
				{
					name: 'Karisha',
					watching: 326,
					age: 33,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-50-13-081.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5130.jpg`),
				},
				{
					name: 'Natasha',
					watching: 124,
					age: 34,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-51-46-553.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5131.jpg`),
				},
				{
					name: 'Elena',
					watching: 145,
					age: 30,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-53-09-849.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5132.jpg`),
				},
				{
					name: 'Diana',
					watching: 318,
					age: 24,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-54-49-516.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5133.jpg`),
				},
				{
					name: 'Darcy',
					watching: 470,
					age: 22,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-56-20-537.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5134.jpg`),
				},
				{
					name: 'Maria',
					watching: 289,
					age: 29,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-02-01-885.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5135.jpg`),
				},
				{
					name: 'Alyona',
					watching: 364,
					age: 23,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-03-41-207.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5136.jpg`),
				},
				{
					name: 'Farisha',
					watching: 50,
					age: 583,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-05-09-563.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5137.jpg`),
				},
				{
					name: 'Olechka',
					watching: 342,
					age: 31,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-08-21-679.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5139.jpg`),
				},
				{
					name: 'Diana',
					watching: 453,
					age: 27,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-11-08-163.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5140.jpg`),
				},
				{
					name: 'Nata ',
					watching: 435,
					age: 24,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-13-18-805.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5138.jpg`),
				},
				{
					name: 'Ivanka',
					watching: 380,
					age: 24,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-17-06-223.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5142.jpg`),
				},
			]
		}
	},
	created() {
		this.updatePaginationVideo();
	},
	methods: {
		showModalSignUp() {
			this.$parent.$refs.modalSignUp.onShow(true);
    },
		updatePaginationVideo: function () {
			
			this.pagination.total = this.models.length;

			let firstIndex = (this.pagination.page-1)*this.pagination.per_page;

			let filteredArr = this.models.slice(firstIndex, (this.pagination.per_page + firstIndex));

			this.filtered = [];

			filteredArr.forEach((item, index)=>{
				this.filtered[index] = item;
			});
		},
	},
}