import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/Home.vue";

import PageNotFound from "@/views/PageNotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
		meta: {
			title: 'Enjoy Live Webcam with Ukrainian and Russian Girls'
		}
  },
  {
    path: "/:catchAll(.*)",
		// path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
		meta: {
			title: '404'
		}
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
